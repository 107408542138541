






































































import { Component, Mixins } from 'vue-property-decorator'
import ViewMode116 from '@carbon/icons-vue/es/view--mode-1/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Settings16 from '@carbon/icons-vue/es/settings/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Add16 from '@carbon/icons-vue/es/add/16'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import isEmpty from 'lodash/isEmpty'
import TablePagination from '@/partials/tables/Pagination.vue'

import PermissionsMixin from '@/mixins/permissionsMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'

@Component({
  components: {
    TablePagination,
    ViewMode116,
    Settings16,
    TrashCan16,
    Edit16,
    Add16,
    GenericModalDelete
  }
})
export default class ExamsScheduledIndex extends Mixins(PermissionsMixin, FlashMessageMixin) {
  filteredData: Array<ExamSchedule> = []
  exams: Array<ExamSchedule> = []

  examsTotal = 0

  selectedExam: ExamSchedule = {} as ExamSchedule
  deleteModalIsOpened = false

  created () {
    this.fetchExams()
  }

  fetchExams (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get('exam/schedule?status=upcoming', {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.filteredData = this.exams = camelCaseKeys(response.data.data, { deep: true })
        this.examsTotal = response.data.total
      })
  }

  postSubmit (message: string) {
    this.deleteModalIsOpened = false

    const flashMessage = {
      message: message,
      isSuccess: true
    }

    this.setFlashMessage(flashMessage)

    this.fetchExams()
  }

  displayExamType (type: string) {
    const name: Record<string, string> = {
      test: 'Prova',
      practice: 'Simulado',
      single: 'Exercício Avulso'
    }
    return name[type]
  }

  search () {
    console.log('Busca')
  }

  onPageChange (pagination: Pagination) {
    this.fetchExams(pagination.page, pagination.length)
  }

  get deleteUrl () {
    return isEmpty(this.selectedExam) ? '' : `exam/${this.selectedExam.exam.id}/schedule/${this.selectedExam.id}`
  }
}
