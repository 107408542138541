



























































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import moment from 'moment'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { mask } from 'vue-the-mask'

import ServicesByRegionalField from '@/partials/forms/components/ServicesByRegional.vue'
import flashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import FinishExamModal from '@/partials/modals/Exams/Schedule/FinishExam.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import DateInput from '@/partials/forms/components/DateTime/DateSimple.vue'

@Component({
  directives: { mask },
  components: {
    DateInput,
    ValidationProvider,
    ValidationObserver,
    ServicesByRegionalField,
    NotifyOnFailed,
    FinishExamModal,
    PasswordConfirmField
  }
})
export default class ExamsScheduledEdit extends Mixins(flashMessageMixin, ValidatorConfigMixin) {
  form: Record<string, any> = {
    examId: '',
    scheduledTo: '',
    availableOn: '',
    availableUp: '',
    completedAt: null,
    services: [],
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  availableOn = {
    time: {
      hour: '13',
      minutes: '00'
    }
  }

  scheduledTo = {
    time: {
      hour: '13',
      minutes: '00'
    }
  }

  availableUp = '15'

  done = true
  exams = []
  finishExamIsOpened = false

  submit () {
    this.done = false
    this.formatFormDates()

    axios.put(`exam/${this.$route.params.examId}/schedule/${this.$route.params.scheduleId}`, snakeCaseKeys(this.form, { deep: true }))
      .then(() => {
        const flashMessage = {
          isSuccess: true,
          message: 'Agendamento editado com sucesso.'
        }
        this.$router.push({ name: 'ExamsScheduled' }, () => this.setFlashMessage(flashMessage))
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  async finishedExam () {
    const flashMessage = {
      isSuccess: true,
      message: 'Prova finalizada com sucesso.'
    }
    await this.toggleModal('finishExamIsOpened')
    this.$router.push({
      name: 'ExamsFinishedReport',
      params: {
        examId: this.$route.params.examId,
        scheduleId: this.$route.params.scheduleId
      }
    }, () => this.setFlashMessage(flashMessage))
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  created () {
    axios.get('exam')
      .then(response => {
        this.exams = response.data.data
      })

    axios.get(`exam/${this.$route.params.examId}/schedule/${this.$route.params.scheduleId}`)
      .then((response) => {
        const responseData = camelCaseKeys(response.data.data, { deep: true })
        const scheduledToMoment = moment(responseData.scheduledTo)
        const availableOnMoment = moment(responseData.availableOn)

        this.form = {
          examId: responseData.examId,
          scheduledTo: scheduledToMoment.format('YYYY-MM-DD HH:mm:ss'),
          completedAt: responseData.completedAt,
          availableOn: availableOnMoment.format('YYYY-MM-DD HH:mm:ss'),
          availableUp: moment(responseData.availableUp).format('YYYY-MM-DD HH:mm:ss'),
          services: responseData.services.map((service: ExamService) => service.serviceId),
          confirm: '',
          errors: {
            confirm: ''
          }
        }

        const [scheduledToHour, scheduledToMinutes] = [scheduledToMoment.hour(), scheduledToMoment.minute()]

        this.scheduledTo.time = {
          hour: scheduledToHour.toString(),
          minutes: scheduledToMinutes.toString()
        }

        const [availableOnHour, availableOnMinutes] = [availableOnMoment.hour(), availableOnMoment.minute()]

        this.availableOn.time = {
          hour: availableOnHour.toString(),
          minutes: availableOnMinutes.toString()
        }

        this.availableUp = moment(responseData.availableUp).diff(scheduledToMoment, 'minutes').toString()
      })
  }

  formatFormDates () {
    let { hour, minutes } = this.scheduledTo.time
    this.form.scheduledTo = moment(this.form.scheduledTo).format(`YYYY-MM-DD ${this.leadingZero(hour)}:${this.leadingZero(minutes)}:00`)

    hour = this.availableOn.time.hour
    minutes = this.availableOn.time.minutes

    this.form.availableOn = moment(this.form.availableOn).format(`YYYY-MM-DD ${this.leadingZero(hour)}:${this.leadingZero(minutes)}:00`)

    this.form.availableUp = moment(this.form.scheduledTo).add(this.availableUp, 'minute').format('YYYY-MM-DD HH:mm:00')
  }

  private leadingZero (num: string) {
    return num.length === 2 ? num : '0' + num
  }
}
