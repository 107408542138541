var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-8"},[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('router-link',{attrs:{"to":{name: 'ExamsScheduled'}}},[_c('cv-button',{staticClass:"whitespace-no-wrap",attrs:{"kind":"secondary"}},[_vm._v(" Voltar para a Lista de Provas Agendadas ")])],1),_c('div',{staticClass:"ml-3"},[(_vm.done)?_c('cv-button',{on:{"click":function($event){_vm.finishExamIsOpened = true}}},[_vm._v(" Finalizar Prova ")]):_c('cv-button-skeleton')],1)],1),_c('ValidationObserver',{staticClass:"flex flex-col lg:flex-row pt-8",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('div',{staticClass:"w-full lg:w-1/3 xl:w-1/4"},[_c('h1',{staticClass:"text-3xl"},[_vm._v(" Agendar Prova ")]),_c('div',{staticClass:"mt-3"},[_c('ValidationProvider',{staticClass:"mt-4",attrs:{"name":"modelo","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-dropdown',{class:errors.length ? 'mb-2' : '',attrs:{"label":"Selecionar modelo","invalid-message":errors[0],"placeholder":"Selecione um modelo"},model:{value:(_vm.form.examId),callback:function ($$v) {_vm.$set(_vm.form, "examId", $$v)},expression:"form.examId"}},_vm._l((_vm.exams),function(exam){return _c('cv-dropdown-item',{key:exam.id,attrs:{"value":exam.id}},[_vm._v(" "+_vm._s(exam.code)+" ")])}),1)]}}],null,true)}),_c('DateInput',{staticClass:"pt-12",attrs:{"label":"Disponibilizar às","min-date":_vm.moment().format('YYYY-MM-DD')},model:{value:(_vm.form.availableOn),callback:function ($$v) {_vm.$set(_vm.form, "availableOn", $$v)},expression:"form.availableOn"}}),_c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"flex"},[_c('ValidationProvider',{attrs:{"name":"hour","rules":("required|max_value:" + (_vm.scheduledTo.time.hour)),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],staticClass:"no-label",attrs:{"placeholder":"13","invalid-message":errors[0]},model:{value:(_vm.availableOn.time.hour),callback:function ($$v) {_vm.$set(_vm.availableOn.time, "hour", $$v)},expression:"availableOn.time.hour"}})]}}],null,true)}),_c('div',{staticClass:"flex items-center"},[_vm._v(" : ")]),_c('ValidationProvider',{attrs:{"name":"minutes","rules":("required|max_value:" + (_vm.scheduledTo.time.hour === _vm.availableOn.time.hour ? _vm.scheduledTo.time.minutes : '99')),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],staticClass:"no-label",attrs:{"placeholder":"45","invalid-message":errors[0]},model:{value:(_vm.availableOn.time.minutes),callback:function ($$v) {_vm.$set(_vm.availableOn.time, "minutes", $$v)},expression:"availableOn.time.minutes"}})]}}],null,true)})],1),_c('label',{staticClass:"bx--label mt-1"},[_vm._v("Horário de Brasília GMT(-03:00)")])]),_c('DateInput',{staticClass:"pt-12",attrs:{"label":"Iniciar prova às","min-date":_vm.form.availableOn},model:{value:(_vm.form.scheduledTo),callback:function ($$v) {_vm.$set(_vm.form, "scheduledTo", $$v)},expression:"form.scheduledTo"}}),_c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"flex"},[_c('ValidationProvider',{attrs:{"name":"hour","rules":"required|max_value:23","vid":"hour","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],staticClass:"no-label",attrs:{"placeholder":"13","invalid-message":errors[0]},model:{value:(_vm.scheduledTo.time.hour),callback:function ($$v) {_vm.$set(_vm.scheduledTo.time, "hour", $$v)},expression:"scheduledTo.time.hour"}})]}}],null,true)}),_c('div',{staticClass:"flex items-center"},[_vm._v(" : ")]),_c('ValidationProvider',{attrs:{"name":"minutes","rules":"required|max_value:59","vid":"minute","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],staticClass:"no-label",attrs:{"placeholder":"45","invalid-message":errors[0]},model:{value:(_vm.scheduledTo.time.minutes),callback:function ($$v) {_vm.$set(_vm.scheduledTo.time, "minutes", $$v)},expression:"scheduledTo.time.minutes"}})]}}],null,true)})],1),_c('div',[_c('label',{staticClass:"bx--label mt-1"},[_vm._v("Horário de Brasília GMT(-03:00)")])]),_c('label',{staticClass:"bx--label mt-12"},[_vm._v("Tolerância após Início")]),_c('cv-radio-group',{attrs:{"vertical":true}},[_c('cv-radio-button',{attrs:{"name":"entrance-time","label":"15min","value":"15"},model:{value:(_vm.availableUp),callback:function ($$v) {_vm.availableUp=$$v},expression:"availableUp"}}),_c('cv-radio-button',{attrs:{"name":"entrance-time","label":"30min","value":"30"},model:{value:(_vm.availableUp),callback:function ($$v) {_vm.availableUp=$$v},expression:"availableUp"}})],1)],1)],1)]),_c('div',{staticClass:"w-full lg:w-3/6 lg:ml-3"},[_c('h1',{staticClass:"text-3xl"},[_vm._v(" Local de Aplicação ")]),_c('div',{staticClass:"pt-4"},[_c('h4',{staticClass:"text-xl"},[_vm._v(" Selecionar Serviços ")]),_c('ValidationProvider',{staticClass:"mt-4",attrs:{"name":"services","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ServicesByRegionalField',{attrs:{"label":false},model:{value:(_vm.form.services),callback:function ($$v) {_vm.$set(_vm.form, "services", $$v)},expression:"form.services"}},[(errors.length )?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione pelo menos 1 ")]):_vm._e()])]}}],null,true)})],1),_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}}),_c('div',{staticClass:"pt-4"},[(_vm.done)?_c('cv-button',{on:{"click":function($event){_vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_vm._v(" Editar Agendamento ")]):_c('cv-button-skeleton')],1)],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1),_c('FinishExamModal',{attrs:{"visible":_vm.finishExamIsOpened},on:{"close":function($event){_vm.finishExamIsOpened = false},"submitted":_vm.finishedExam}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }